
$white: #fff !default;
$gray-100: #f8f9fa !default;
$gray-200: #ebebeb !default;
$gray-300: #dee2e6 !default;
$gray-400: #ced4da !default;
$gray-500: #adb5bd !default;
$gray-600: #888 !default;
$gray-700: #666 !default;
$darck: #111 !default;
$gray-900: #222 !default;
$black: #000 !default;

$blue: #375a7f !default;
$blue_origin: #0d6efd !default;
$indigo: #6610f2 !default;
$purple: #6f42c1 !default;
$pink: #e83e8c !default;
$red: #e74c3c !default;
$orange: #ff622e !default;
$yellow: #f39c12 !default;
$green: #00bc8c !default;
$teal: #20c997 !default;
$cyan: #3498db !default;

$primary: $blue !default;
$secondary: $gray-700 !default;
$success: $green !default;
$info: $cyan !default;
$warning: $yellow !default;
$danger: $red !default;

$dark: $gray-900 !default;

$min-contrast-ratio: 3 !default;

$spacer: 1rem !default;
$spacers: ( 0: 0, 1: $spacer * .25, 2: $spacer * .5, 3: $spacer, 4: $spacer * 1.5, 5: $spacer * 3, ) !default;

$body-bg: $gray-700 !default;
$body-color: $white !default;

$link-color: $white !default;
$link-hover-color: $blue_origin !default;

$h1-font-size: 3rem !default;
$h2-font-size: 2.5rem !default;
$h3-font-size: 2rem !default;
$text-muted: $gray-600 !default;

$table-border-color: $gray-700 !default;

$table-bg-scale: 0 !default;

$input-line-height: .1 !default;
$input-bg: $gray-700 !default;
$input-color: $white !default;
$input-padding-y: 3px !default;

$input-border-color: $body-bg !default;
$input-group-addon-color: $gray-500 !default;
$input-group-addon-bg: $gray-700 !default;

$form-check-input-bg: $white !default;
$form-check-input-border: none !default;

$form-file-button-color: $white !default;


$dropdown-bg: $gray-900 !default;
$dropdown-border-color: $gray-700 !default;
$dropdown-divider-bg: $gray-700 !default;
$dropdown-link-color: $white !default;
$dropdown-link-hover-color: $white !default;
$dropdown-link-hover-bg: $primary !default;


$nav-link-padding-x: 2rem !default;
$nav-link-disabled-color: $gray-500 !default;
$nav-tabs-border-color: $gray-700 !default;
$nav-tabs-link-hover-border-color: $nav-tabs-border-color $nav-tabs-border-color transparent !default;
$nav-tabs-link-active-color: $white !default;
$nav-tabs-link-active-border-color: $nav-tabs-border-color $nav-tabs-border-color transparent !default;


$navbar-padding-y: 1rem !default;
$navbar-dark-color: $gray-900 !default;
$navbar-dark-hover-color: $gray-900 !default;
$navbar-light-color: $gray-900 !default;
$navbar-light-hover-color: $gray-900 !default;
$navbar-light-active-color: $gray-900 !default;
$navbar-light-toggler-border-color: rgba($gray-900, .1) !default;

$pagination-color: $white !default;
$pagination-bg: $success !default;
$pagination-border-width: 0 !default;
$pagination-border-color: transparent !default;
$pagination-hover-color: $white !default;
$pagination-hover-bg: lighten($success, 10%) !default;
$pagination-hover-border-color: transparent !default;
$pagination-active-bg: $pagination-hover-bg !default;
$pagination-active-border-color: transparent !default;
$pagination-disabled-color: $white !default;
$pagination-disabled-bg: darken($success, 15%) !default;
$pagination-disabled-border-color: transparent !default;

$card-cap-bg: $gray-700 !default;
$card-bg: $darck !default;

$popover-bg: $darck !default;
$popover-header-bg: $gray-700 !default;

$toast-background-color: $gray-700 !default;
$toast-header-background-color: $darck !default;

$modal-content-bg: $darck !default;
$modal-content-border-color: $gray-700 !default;
$modal-header-border-color: $gray-700 !default;

$progress-bg: $gray-700 !default;

$list-group-color: $body-color !default;
$list-group-bg: $darck !default;
$list-group-border-color: $gray-700 !default;
$list-group-hover-bg: $gray-700 !default;
$list-group-action-hover-color: $list-group-color !default;
$list-group-action-active-bg: $gray-900 !default;

$breadcrumb-padding-y: .375rem !default;
$breadcrumb-padding-x: .75rem !default;
$breadcrumb-bg: $gray-700 !default;
$breadcrumb-border-radius: .25rem !default;

$btn-close-color: $white !default;
$btn-close-opacity: .4 !default;
$btn-close-hover-opacity: 1 !default;

$pre-color: inherit !default;

$theme-colors: ( "primary": $primary, "secondary": $secondary, "success": $success, "info": $info, "warning": $warning, "danger": $danger, "light": $dark, "dark": $dark ) !default;

@import "bootstrap";
